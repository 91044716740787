/* eslint-disable react-hooks/rules-of-hooks */
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Button as MuiButton,
  Paper,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TextField,
  capitalize,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import {MoreVert, EditOff, Edit} from '@mui/icons-material';
import {
  Container, Table, Row, Col, Button, Tabs, Tab, Form,
} from 'react-bootstrap';

import {
  distinct,
  floatVal,
  Hash,
  HashOf,
  intVal,
  keyList,
  keys,
  Nullable,
  replaceAt,
  strVal,
} from '@jamesgmarks/utilities';

import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  roundedInvoiceLineTotal,
  roundFloatToCents,
  roundFloatToCentsAsNumber,
  toDollarAmount,
} from '../../../app-utils';

import { loadCurrentClient } from '../../../redux/features/clients/actions';
import {
  loadCurrentWIPInvoice as loadCurrentInvoice,
  triggerInvoicePdfGeneration,
  updateInvoiceAddress,
  updateInvoiceLineItems,
  updateInvoiceReferenceNumber,
} from '../../../redux/features/invoices/actions';
import { useAuth } from '../../../customHooks/useAuth';

import { addCredit, applyCredit, loadAvailableCreditData } from '../../../redux/features/credits/actions';
import { EUnitOfMeasure } from './InvoicesContainer';
import { FreshbooksClientLink } from '../../parts/freshbooks/FreshbooksClientLink';
import { FreshbooksInvoiceLink, getFreshbooksUrlForInvoice } from '../../parts/freshbooks/FreshbooksInvoiceLink';
import { AddCreditModal } from './AddCreditModal';
import { ApplyCreditModal } from './ApplyCreditModal';
import { RequirePermission } from '../../parts/RequirePermission';
import { useAppSelector } from '../../../redux/hooks';

import pdfFileIcon from '../../../assets/images/pdf.png';
import { REACT_APP_API_ROOT_URI } from '../../../App';

import { arrangeLinesForInvoice } from '../../../app-utils/invoice-lines';
import { EditableContent } from '../../parts/EditableContent';
import { EInvoiceLineGenerationType, EInvoiceLineType } from '../../../../../entities/hydra/InvoiceLine';
import { IBillingAddress } from '../../../../../rentsync-api/invoicing/IBillingAddress';
import {
  Invoice,
  InvoiceLine as InvoiceLineData,
  UsageItem,
} from '../../../../../entities/hydra';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { getInvoiceState } from '../../../app-utils/invoices';
import { BillingAddressCard } from '../../parts/BillingAddressCard';
import { displayFile } from '../../../app-utils/display-file';
import { StyledTableCell, StyledTableRow } from '../../parts/mui/StyledTables';
import { ICreditNoteSummaryItem } from '../../../interfaces/ICreditSummaryItem';
import { EHydraPaymentState, IPayment } from '../../../redux/features/payments/interfaces';
import { getPartsFromIsoString } from '../../../app-utils/helpers';
import { ConditionalWrap } from '../../parts/ConditionalWrapper';
import { applyCreditNote } from '../../../redux/features/credit-notes/actions';
import { IClients } from '@llws/typeorm-entities/dist/interfaces/lift';
import { showMessage } from 'src/redux/features/messaging/actions';
import { performApiActionRequest, TActionResponse } from 'src/redux/apiActionRequest';
import { HHConfirmationModal } from 'src/components/parts/HHConfirmationModal';
import { isApiErrorResponse } from '@hydra/internal';
import { IApiErrorResponse, IApiQueryResponse } from '@llws/api-common';

enum EDirtyLineProps {
  description = 'description',
  item = 'item',
  quantity = 'quantity',
  unitCost = 'unitCost',
}

const InvoiceLineUsageItem = ({
  usageItem,
  index,
  client,
}: {
  usageItem: UsageItem,
  index: number,
  client: Nullable<IClients>,
}) => (
  <tr>
    <td>{usageItem.subscriptionId}</td>
    <td>
        ({usageItem.buildingId}) {(client?.buildings ?? []).find((b) => b.id === usageItem.buildingId)?.buildingName}
    </td>
    <td>
      <ConditionalWrap
        condition={!!usageItem.unitOfMeasure}
        wrap={
          (wrappedChildren: ReactElement) => (
            <Tooltip arrow placement='left' title={usageItem.unitOfMeasure}>
              {wrappedChildren}
            </Tooltip>
          )
        }
      >
        {usageItem.unitOfMeasure ? <div>{EUnitOfMeasure[usageItem.unitOfMeasure]} &nbsp;&#9432;</div> : <div />}
      </ConditionalWrap>
    </td>
    <td>{usageItem.amount}</td>
    <td>{roundFloatToCents(floatVal(usageItem.itemCost) ?? 0)}</td>
    <td>{roundFloatToCents(floatVal(usageItem.amount) * (floatVal(usageItem.itemCost) ?? 0))}</td>
  </tr>
);

const InvoiceLine = ({
  line,
  dirtyProps,
  index,
  client,
  isEditable,
  onEditLineContent,
  suppressCredits,
  onCreditButton,
}: {
  line: InvoiceLineData,
  dirtyProps?: (keyof InvoiceLineData)[],
  index: number,
  client: Nullable<IClients>,
  isEditable?: boolean,
  onEditLineContent: (attribute: keyof InvoiceLineData, newValue: InvoiceLineData[typeof attribute]) => void,
  suppressCredits?: boolean,
  onCreditButton: () => void,
}) => {
  const [dropDown, setDropDown] = useState(false);

  const usageItems = (line.usageItems ?? []);

  const usageTotal = usageItems.reduce((acc, ui) => ({
    amount: acc.amount + floatVal(ui.amount),
    count: acc.amount + 1,
    totalCost: acc.totalCost + (floatVal(ui.itemCost ?? 0)) * floatVal(ui.amount),
  }), {
    amount: 0,
    count: 0,
    totalCost: 0,
  });

  const triggerUpdateIfDirty = (
    attribute: EDirtyLineProps,
    newValue: string,
  ) => {
    const trimmedStrVal = (item: string | number) => strVal(`${item}`.trim());
    const trimmedFloatVal = (item: string | number) => floatVal(`${item}`.trim());
    const transformMap = {
      item: trimmedStrVal,
      description: trimmedStrVal,
      quantity: trimmedFloatVal,
      unitCost: trimmedFloatVal,
    };
    if (transformMap[attribute](newValue) !== transformMap[attribute](line[attribute])) {
      const value = transformMap[attribute](newValue);
      onEditLineContent(attribute, value);
    }
  };

  const isDescriptionLine = useMemo(() => line.lineType === 'info', [ line ]);

  return (<>
    <tr>
      <td>{
        !isDescriptionLine
        && <button style={{ background: 'none', border: 'none', color: '#fff' }} onClick={() => setDropDown(!dropDown)}>
          <span style={{ fontFamily: 'Consolas, Courier, Roboto mono, Fixedsys' }}>[{dropDown ? '-' : '+'}]</span>
        </button>
      } </td>
      <td>
        <EditableContent
          isEditable={isEditable}
          isEdited={dirtyProps?.includes(EDirtyLineProps.item)}
          onBlur={(e) => { triggerUpdateIfDirty(EDirtyLineProps.item, e.target.innerText); }}
          selectOnFocus={true}
        >{line.item}</EditableContent>
      </td>
      <td>
        <EditableContent
          isEditable={isEditable}
          isEdited={dirtyProps?.includes(EDirtyLineProps.description)}
          onBlur={(e) => { triggerUpdateIfDirty(EDirtyLineProps.description, e.target.innerText); }}
          selectOnFocus={true}
        >{line.description}</EditableContent>
      </td>
      <td className='currency-right'>
        $<EditableContent
          isEditable={isEditable && !isDescriptionLine}
          isEdited={dirtyProps?.includes(EDirtyLineProps.unitCost)}
          onBlur={(e) => {
            e.target.innerText = (floatVal(e.target.innerText) || 0).toFixed(2);
            triggerUpdateIfDirty(EDirtyLineProps.unitCost, e.target.innerText);
          }}
          selectOnFocus={true}
        >{(floatVal(line.unitCost) || 0).toFixed(2)}</EditableContent>
      </td>
      <td style={{ textAlign: 'center' }}>
        <EditableContent
          isEditable={isEditable && !isDescriptionLine}
          isEdited={dirtyProps?.includes(EDirtyLineProps.quantity)}
          onBlur={(e) => { triggerUpdateIfDirty(EDirtyLineProps.quantity, e.target.innerText); }}
          selectOnFocus={true}
        >{line.quantity}</EditableContent>
      </td>
      <td className='currency-right'>
        {toDollarAmount((floatVal(line.lineTotal) || 0), 'brackets')}
        {/* Only provide credit button if user has credit permission */}
        {!suppressCredits && !isDescriptionLine && <RequirePermission grant="WRITE_CREDITS">
          <Button
            onClick={onCreditButton}
            size="sm"
            style={{ marginLeft: '0.75rem' }}
          >
            Credit
          </Button>
        </RequirePermission>}
      </td>
    </tr>
    {dropDown && <tr>
      <td colSpan={6}>
        <table className='mx-auto' width='98%'>
          <tr>
            <th>Subscription Id</th>
            <th>Building</th>
            <th>Unit</th>
            <th>Amount</th>
            <th>Unit Cost</th>
            <th>Subtotal</th>
          </tr>
          <tbody>
            {(usageItems || [])
              .slice()
              .sort((a, b) => (a.buildingId ?? 0) - (b.buildingId ?? 0))
              .filter((usageItem) => floatVal(usageItem.amount) > 0)
              .map((usageItem, i) => (
                <InvoiceLineUsageItem key={i} index={i} usageItem={usageItem} client={client} />))
            }
            <tr>
              <td>{usageTotal.count} Items</td>
              <td></td>
              <td></td>
              <td>{usageTotal.amount}</td>
              <td></td>
              <td>{roundFloatToCents(usageTotal.totalCost)}</td>
            </tr>
          </tbody>
        </table>

      </td>
    </tr>}
  </>
  );
};

const getTaxDataByInvoiceLine = (invoice: Invoice) => {
  const { taxName, taxRate } = invoice;

  const taxData : HashOf<{ name: string, rate: number, amount: number }> = (
    invoice.lines.reduce((summary, line) => {
      const curKey1 = `${taxName}:${taxRate}`;
      return ({
        ...summary,
        ...({
          [curKey1]: {
            name: taxName,
            rate: (floatVal(taxRate || 0)) * 100,
            amount: (
              (summary[curKey1]?.amount ?? 0)
            + (roundFloatToCentsAsNumber(+line.lineTotal) * (floatVal(taxRate) || 0))
            ),
          },
        }),
      });
    }, {} as HashOf<{ name: string, rate: number, amount: number }>));

  const keys = Object.keys(taxData);
  const roundedTaxData = keys.reduce((acc, key)=> (
    {
      ...acc,
      [key]: {
        ...taxData[key],
        amount: roundFloatToCentsAsNumber(taxData[key].amount),
      },
    }),
  {} as HashOf<{ name: string, rate: number, amount: number }>);
  return roundedTaxData;
};

const loadCreditData = (invoice: Invoice) => {
  if (invoice) {
    loadAvailableCreditData({
      freshbooksClientId: intVal(invoice.freshbooksClientId),
      currencyCode: invoice.currency,
      taxRate: invoice.taxRate,
    });
  }
};

const representAmountAsNegative = (
  (amount: number) => <span className='negativeNumber'>{`(${roundFloatToCents(amount)})`}</span>
);

const numberAs = (amount: number, showAs: 'positive' | 'negative') => <>{
  showAs === 'positive'
    ? roundFloatToCents(amount)
    : representAmountAsNegative(amount)
}</>;

export const InvoiceSingleView = () => {
  const invoice = useAppSelector((state) => state.invoices.currentInvoiceWIPWithUpdatedSendHistory?.invoice);
  const sendHistories = useAppSelector(
    (state) => state.invoices.currentInvoiceWIPWithUpdatedSendHistory?.updatedInvoiceSendHistory,
  );
  const client = useAppSelector((state) => state.clients.currentClient);
  const invoiceDownloadUrl = useAppSelector((state) => state.invoices.invoiceDownloadUrl);
  const { creditData, loadedState: creditsLoadedState } = useAppSelector((state) => state.credits);
  const monthLocks = useAppSelector(state => state.invoices.monthLocks);

  const { isDeveloper } = useAuth();
  const { invoiceId, invoiceNumber } = useParams<{ invoiceId?: string, invoiceNumber?: string }>();

  const [ selectedLine, setSelectedLine ] = useState(null as Nullable<InvoiceLineData>);

  const [ referenceNumber, setReferenceNumber ] = useState(invoice?.referenceNumber ?? '');
  const [ referenceNumberError, setReferenceNumberError] = useState(false);
  const [ referenceNumberHelperText, setReferenceNumberHelperText] = useState('');

  const freshbooksAccountId = useAppSelector(state => state.systemInfo.freshbooksAccountId);

  // Visibility controls
  const [ showAddCreditModal, setShowAddCreditModal ] = useState(false);
  const [ showApplyCreditModal, setShowApplyCreditModal ] = useState(false);
  const [ showSpentCreditInfo, setShowSpentCreditInfo ] = useState(true);
  const [ showClientCreditSummary, setShowClientCreditSummary ] = useState(true);
  const [ showClientCreditNoteSummary, setShowClientCreditNoteSummary ] = useState(true);
  const [ showAwardedCreditInfo, setShowAwardedCreditInfo ] = useState(true);

  const [ showUpdateReferenceNumberButton, setShowUpdateReferenceNumberButton] = useState(false);

  // Toggles
  const [ filterCreditSummaryToInvoice, setFilterCreditSummaryToInvoice ] = useState(true);
  const [ filterCreditNoteSummaryToInvoice, setFilterCreditNoteSummaryToInvoice ] = useState(true);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // State used to manage line editing
  const [ dirtyLineProps, setDirtyLineProps ] = useState<((keyof InvoiceLineData)[])[]>([]);
  const [ lineData, setLineData ] = useState(invoice?.lines ?? []);

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [
    dropDownOptionSelected, 
    setDropDownOptionSelected,
  ] = useState<Nullable<keyof typeof actionDropdownOptions>>(null);

  const handleReferenceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 0 - 20 alpha-numeric characters...
    if (e.target.value.match(/^[\d\s\w]{0,20}$/)) {
      setReferenceNumber(e.target.value);
      setReferenceNumberError(false);
      setReferenceNumberHelperText('');
    } else {
      setReferenceNumberError(true);
      setReferenceNumberHelperText('0 - 20 alpha-numeric characters');
    }

    setShowUpdateReferenceNumberButton(true);
  };

  const toggleShowCreditSummary = () => { setShowClientCreditSummary(!showClientCreditSummary); };

  const toggleShowCreditNoteSummary = () => { setShowClientCreditNoteSummary(!showClientCreditNoteSummary); };

  useEffect(() => {
    if (invoiceId) {
      loadCurrentInvoice({
        invoiceId: invoiceId ? intVal(invoiceId) : undefined,
        invoiceNumber,
        forceRefresh: false,
      });
    }
  }, [ invoiceId, invoiceNumber ]);

  const getSortedInvoiceLines = useCallback(() => {
    return arrangeLinesForInvoice(invoice?.lines ?? [], { filterZeroDollarCharges: false });
  }, [ invoice?.lines ]);

  const viewGcpInvoicePdf = useCallback(async () => {
    if (!invoice) {
      return;
    }

    const url = `${REACT_APP_API_ROOT_URI}/downloads/download_invoice_pdf/${invoice.invoiceNumber}`;
    const filename = invoice.invoiceNumber ?? `PV_${invoice.id}`;

    await displayFile(url, filename);
  }, [invoice]);

  useEffect(() => {
    if (invoice) {
      setLineData(getSortedInvoiceLines());
      const primaryClientId = invoice.billingAccount.primaryClientId;
      loadCurrentClient({ clientId: primaryClientId ?? 0, forceRefresh: false });
      loadCreditData(invoice);
    }
  }, [ invoice, getSortedInvoiceLines ]);

  useEffect(() => {
    if (invoice) {
      setReferenceNumber(invoice.referenceNumber);
    }
  }, [ invoice ]);

  const billingProfile = useMemo(
    () => invoice?.billingAccount?.billingProfile,
    [ invoice?.billingAccount?.billingProfile ],
  );

  const originalAddress = useMemo(
    () => {
      const parsedOriginalAddress = JSON.parse(invoice?.invoiceRaw?.originalAddress ?? '{}') as IBillingAddress;
      return keyList(parsedOriginalAddress).length ? parsedOriginalAddress : null;
    },
    [ invoice?.invoiceRaw?.originalAddress ],
  );

  const override = useMemo(
    () => {
      const parsedAddressOverride = JSON.parse(invoice?.addressOverride ?? '{}') as IBillingAddress;
      return keyList(parsedAddressOverride).length ? parsedAddressOverride : null;
    },
    [ invoice?.addressOverride ],
  );

  const usedAddress = useMemo(
    () => (override?? originalAddress?? billingProfile ) as IBillingAddress,
    [ override, originalAddress, billingProfile ],
  );

  const billingAddress = useMemo(
    () => ({
      organization: usedAddress?.organization,
      // TODO: do we need/use `firstName`, `lastName` or `rName` (and what the heck is `rName`)
      street1: usedAddress?.street1,
      street2: usedAddress?.street2,
      city: usedAddress?.city,
      province: usedAddress?.province,
      postalCode: usedAddress?.postalCode,
      country: usedAddress?.country,
    }) as IBillingAddress,
    [  usedAddress ],
  );

  const billingAccount = useMemo(
    () => invoice?.billingAccount,
    [ invoice?.billingAccount ],
  );

  const isMonthLocked = useMemo(() => {
    const invoiceDate = new Date(invoice?.invoiceDate ?? '');
    return invoice ? monthLocks[`${invoiceDate.getFullYear()}-${invoiceDate.getMonth() + 1}`] ?? false : false;
  }, [ invoice, monthLocks ]);

  const isEditable = useMemo(() => {
    return !isMonthLocked && (invoice?.hydraState === 'unsent' || invoice?.freshbooksState === 'draft');
  }, [ isMonthLocked, invoice ]);

  const hasDirtyLines = useMemo(() => (
    (Object.keys(dirtyLineProps).length > 0)
    || lineData.some((line) => !line.id)
  ), [ dirtyLineProps, lineData ]);

  const thirdPartyInvoiceUrl: string = useMemo(() => {
    if (!invoice?.xeroInvoiceId && invoice?.thirdPartyInvoiceId) {
      return getFreshbooksUrlForInvoice(freshbooksAccountId, invoice?.thirdPartyInvoiceId);
    }
    return `https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${invoice?.xeroInvoiceId}`;
  }, [invoice, freshbooksAccountId]);

  // TODO: Test invoices with credits

  // Original subtotal before any inline edits.
  const invoiceSubtotal = roundFloatToCentsAsNumber(
    (invoice?.lines ?? []).reduce((acc: number, line) => acc + (roundedInvoiceLineTotal(line)), 0),
  );

  // For comparison (to ensure that changes do not change final invoice value), subtotal after manual changes.
  const invoiceSubtotalAfterEdit = roundFloatToCentsAsNumber(
    (lineData ?? []).reduce((acc: number, l) => acc + (roundedInvoiceLineTotal(l)), 0),
  );

  const taxData = invoice ? getTaxDataByInvoiceLine(invoice) : {};

  const totalTaxAmount = roundFloatToCentsAsNumber(invoiceSubtotalAfterEdit * +(invoice?.taxRate || '0'));
  const invoiceTotal = invoiceSubtotal + totalTaxAmount;
  const outstandingAmount = (
    invoice?.freshbooksOutstandingAmount
      ? floatVal(invoice?.freshbooksOutstandingAmount)
      : invoiceTotal ?? 0
  );
  const paidAmount = invoiceTotal - floatVal(outstandingAmount);

  const hasDownload = useMemo(() => (invoice?.pdfGenerations ?? []).length > 0, [invoice?.pdfGenerations]);

  const clearLineChanges = () => {
    setLineData(getSortedInvoiceLines());
    setDirtyLineProps([]);
  };

  const updateInvoiceLines = async () => {
    const updatableLines = lineData.filter((line) => (
      Object.keys(dirtyLineProps).includes(`${line.id}`) || !line.id
    ));
    const linesWithEmptyDescription = updatableLines.filter((line) => !line.description);
    if (linesWithEmptyDescription.length > 0) {
      showMessage({
        message: `Invoices line changes with empty description will be undone and not be saved`,
        severity: 'error',
      });
    }
    await updateInvoiceLineItems(updatableLines);
    clearLineChanges();
  };

  const generatePdfForInvoice = useCallback(
    () => {if (invoice?.id) { triggerInvoicePdfGeneration([ invoice.id ]); }},
    [invoice?.id],
  );

  const addNewDescriptionLine = useCallback(() => {
    const newLine = {
      lineType: 'info' as EInvoiceLineType,
      lineGenerationType: 'manual' as EInvoiceLineGenerationType,
      item: '',
      description: 'Add Description',
      quantity: 1,
      unitCost: '0',
      creditAppliedId: null,
      lineTotal: '0',
      invoiceId: invoice?.id,
    } as InvoiceLineData;
    setLineData([ ...lineData, newLine ]);
  }, [ lineData, setLineData, invoice?.id ]);

  const maxApplicationAmount = Math.min(
    floatVal(invoice?.freshbooksOutstandingAmount
      ?? invoice?.amountInvoiced)
      ?? creditData?.availableSubtotal,
    creditData?.availableSubtotal ?? 0,
  ); // lowest of invoice max subtotal and total available credit

  const handleDropdownClicked = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleModalOptionsClicked = (option: keyof typeof actionDropdownOptions) => {
    setConfirmationModalOpen(true);
    setDropDownOptionSelected(option);
  };

  const handleDropdownOptionSelected = (
    event: React.MouseEvent<HTMLElement>,
    option: string,
  ) => {
    (
      actionDropdownOptions[option].showModal 
        ? handleModalOptionsClicked(option) 
        : actionDropdownOptions[option].action()
    );
    setAnchorEl(null);
  };

  const openThirdPartyInvoice = useCallback(() => {
    if (invoice && invoice.thirdPartyInvoiceId) {
      window.open(thirdPartyInvoiceUrl, '_blank');
    }
  }, [invoice, thirdPartyInvoiceUrl]);

  const showActionResultMessage = (response: IApiErrorResponse | IApiQueryResponse<TActionResponse<Hash>>) => {
    if (isApiErrorResponse(response)) {
      showMessage({
        message: 'Received an error from the server',
        severity: 'error',
      });
      return;
    }
    const successAction = Array.isArray(response.data) 
      ? response.data.every((r) => r.actionResponse.success) 
      : response.data.actionResponse.success;
    if (successAction) {
      showMessage({
        message: 'Successfully completed action',
        severity: 'success',
      });
      return;
    }
    showMessage({
      message: 'Failed to complete action',
      severity: 'error',
    });
    return;
  };

  const disableInvoiceDelete = useMemo(() => (
    isMonthLocked 
      || (
        invoice?.hydraState !== 'sent' 
        || invoice?.freshbooksState !== 'draft'
        || invoice?.generationType !== 'manual'
      )
  ), [invoice, isMonthLocked]);
  
  const disableInvoiceRepublish: boolean = useMemo(() => (
    isMonthLocked 
    || (
      invoice?.hydraState !== 'sent' 
      || invoice?.freshbooksState !== 'draft')
  ), [invoice, isMonthLocked]);

  const handleInvoiceDelete = useCallback(async () => {
    setConfirmationModalOpen(false);
    const actionResult = await performApiActionRequest<TActionResponse<Hash>>('deleteInvoices', {
      invoiceNumbers: [invoice?.invoiceNumber],
    });
    showActionResultMessage(actionResult);
  }, [invoice]);

  const handleInvoiceRepublish = useCallback(async () => {
    setConfirmationModalOpen(false);
    const actionResult = await performApiActionRequest<TActionResponse<Hash>>('republishInvoicesToXero', {
      invoiceNumbers: [invoice?.invoiceNumber],
    });
    showActionResultMessage(actionResult);
  }, [invoice]);

  const actionDropdownOptions: HashOf<{
    action: Function, disable: boolean, hasPermission: Nullable<string>, showModal: boolean
  }> = useMemo(
    () => ({
      'View PDF': {action: viewGcpInvoicePdf, disable: !hasDownload, hasPermission: null, showModal: false},
      'Generate PDF': {action: generatePdfForInvoice, disable: false, hasPermission: null, showModal: false},
      'Third Party Invoice': {action: openThirdPartyInvoice, disable: false, hasPermission: null, showModal: false},
      'Delete Invoice': {
        action: handleInvoiceDelete, 
        disable: disableInvoiceDelete, 
        hasPermission: 'TRIGGER_INVOICE_OTF_DELETE',
        showModal: true,
      },
      'Republish Invoice': {
        action: handleInvoiceRepublish, 
        disable: disableInvoiceRepublish, 
        hasPermission: 'TRIGGER_INVOICE_REPUBLISH',
        showModal: true,
      },
    }),
    [
      hasDownload, 
      viewGcpInvoicePdf, 
      generatePdfForInvoice, 
      openThirdPartyInvoice, 
      disableInvoiceDelete, 
      handleInvoiceDelete,
      disableInvoiceRepublish, 
      handleInvoiceRepublish,
    ],
  );

  return (
    <>
      {
        showAddCreditModal
        && <AddCreditModal
          show={showAddCreditModal}
          line={selectedLine}
          invoice={invoice ?? null}
          onHide={() => { setShowAddCreditModal(false); }}
          onSubmit={(creditData) => addCredit(creditData)}
        />
      }
      {
        showApplyCreditModal
        && <ApplyCreditModal
          show={showApplyCreditModal}
          invoice={invoice ?? null}
          creditData={creditData!}
          onHide={() => { setShowApplyCreditModal(false); }}
          onCreditSubmit={(creditData) => applyCredit(creditData)}
          onCreditNoteSubmit={(creditNoteData) => applyCreditNote(creditNoteData)}
        />
      }
      <Container style={{ paddingBottom: '5rem' }}>
        <Row><Col>&nbsp;</Col></Row>
        <Row className="justify-content-between">
          <Col>
            <div>
              <h4 className="mb-1 mt-1">
                <strong>Invoice #: </strong>
                <span
                  title={`Rentsync: ${invoice?.id}, Xero: ${invoice?.xeroInvoiceId ?? 'n/a'}`} // eslint-disable-line max-len
                >{invoice?.invoiceNumber ?? 'Not published'}</span>
                {' '}
                <span
                  title={isEditable ? 'Editable' : 'Not Editable'}
                >
                  { isEditable ? <Edit /> : <EditOff /> }
                </span>
              </h4>
            </div>
          </Col>
          <Col className="text-right align-top">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-haspopup="true"
              onClick={handleDropdownClicked}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleDropdownClose}
            >
              {Object.keys(actionDropdownOptions).map((option, index) => (
                actionDropdownOptions[option].hasPermission
                  ? <RequirePermission 
                    key={`hasPermission-${option}`} 
                    grant={actionDropdownOptions[option].hasPermission ?? ''}
                  >
                    <MenuItem 
                      key={option} 
                      onClick={(event) => handleDropdownOptionSelected(event, option)}
                      disabled={actionDropdownOptions[option].disable}
                    >
                      {option}
                    </MenuItem>
                  </RequirePermission>
                  :<MenuItem 
                    key={option} 
                    onClick={(event) => handleDropdownOptionSelected(event, option)}
                    disabled={actionDropdownOptions[option].disable}
                  >
                    {option}
                  </MenuItem>
      
              ))}
            </Menu>
            <HHConfirmationModal
              open={isConfirmationModalOpen}
              onClose={() => setConfirmationModalOpen(false)}
              onConfirm={
                dropDownOptionSelected ? () => actionDropdownOptions[dropDownOptionSelected].action() : () => {}
              }
              body={`Are you sure you want to ${dropDownOptionSelected}`}
            />
          </Col>
        </Row>
        <Row><Col>&nbsp;</Col></Row>
        <Row className="align-items-start">
          <Col>
            {
              // TODO: Dry this up
              billingAccount?.clientType === 'client'
              && <div>
                <strong>Client: </strong>
                <Link
                  to={`/clients/${billingAccount.accountId}`}
                >
                  {
                    billingAccount.clients
                      .find(c => c.clientId === billingAccount.primaryClientId)
                      ?.clientName
                    ?? 'Client Name Not found'
                  }
                </Link>
              </div>
            }
            {
              // TODO: Dry this up
              billingAccount?.clientType === 'partner'
              && <div>
                <strong>Partner: </strong>
                <Link
                  to={`/partners/${billingAccount?.accountId}`}
                >
                  {
                    billingAccount.partners
                      .find(p => p.partnerId === billingAccount.primaryClientId)
                      ?.partnerName
                    ?? 'Partner Name Not found'
                  }
                </Link>
              </div>
            }
            <div>
              <strong>Status: </strong>
              { invoice && capitalize(`${getInvoiceState(invoice)}`) }
            </div>
            <div>
              <strong>Amount Outstanding:</strong> {toDollarAmount(invoiceSubtotal + totalTaxAmount)}{/** TODO: (ISSUE:#201) " - amountPaid" */}
            </div>
            <div>
              <strong>Invoice Date:</strong>{` `}
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                {invoice?.invoiceDate?.toString()?.split('T')?.[0]}
              </span>
              {isMonthLocked && <> <FontAwesomeIcon icon={faLock as IconProp} /> Month is locked. </>}
            </div>
            <div>
              <b>Reference Number:</b>{` `}
              <TextField
                error={referenceNumberError}
                FormHelperTextProps={{ style: { fontSize: '0.6rem' } }}
                helperText={referenceNumberHelperText}
                onChange={handleReferenceNumberChange}
                size='small'
                sx={{ width: '95px' }}
                value={referenceNumber ?? ''}
                variant='standard'
              />
              {
                showUpdateReferenceNumberButton &&

                <MuiButton
                  onClick={(e) => {
                    invoice?.id && updateInvoiceReferenceNumber(invoice.id, referenceNumber);
                    setShowUpdateReferenceNumberButton(false);
                  }}
                  sx={{ ml: 1.5 }}
                  size='small'
                >
                Confirm
                </MuiButton>
              }
            </div>
            {invoiceDownloadUrl && <div><a href={invoiceDownloadUrl}>Download</a></div>}
          </Col>
          <Col>
            <BillingAddressCard billingAddress={billingAddress} onSave={(editedAddress:IBillingAddress)=>{
              updateInvoiceAddress(
                invoice?.id,
                editedAddress,
              );
            }} />
          </Col>
        </Row>
        <br />
        <Row className="align-items-end">
          <Col style={{ textAlign: 'right' }}>
          </Col>
        </Row>
        <Table striped bordered hover variant="dark" size="sm">
          <thead>
            <tr>
              <th>[+]</th>
              <th>Item</th>
              <th>Description</th>
              <th>Unit Cost</th>
              <th>Quantity</th>
              <th>Line Total</th>
            </tr>
          </thead>
          <tbody>
            {(lineData || []).map((line, i) => (
              <InvoiceLine
                key={line.id}
                index={i}
                line={line}
                isEditable={isEditable || line.lineType === 'info'}
                dirtyProps={dirtyLineProps[line.id]}
                suppressCredits={Object.keys(dirtyLineProps).length > 0}
                client={client}
                onCreditButton={
                  () => {
                    setSelectedLine(line);
                    setShowAddCreditModal(true);
                  }
                }
                onEditLineContent={
                  (property, value) => {
                    setDirtyLineProps(
                      (oldDirtyLineProps) => ({
                        ...oldDirtyLineProps,
                        ...(
                          line.id
                            ? { [line.id]: distinct([ ...(oldDirtyLineProps[line.id] ?? []), property ]) }
                            : {}
                        ),
                      }),
                    );

                    const lineIndex = lineData.indexOf(line);

                    setLineData(
                      (oldLineData) => {
                        const quantityToUse = (
                          property === EDirtyLineProps.quantity ? value : oldLineData[lineIndex].quantity
                        );
                        const unitCostToUse = (
                          property === EDirtyLineProps.unitCost ? value : oldLineData[lineIndex].unitCost
                        );

                        const lineTotal = (
                          strVal(
                            [ 'quantity', 'unitCost' ].includes(property)
                              ? floatVal(unitCostToUse) * floatVal(quantityToUse)
                              : oldLineData[lineIndex].lineTotal,
                          )
                        );

                        return (
                          replaceAt(
                            oldLineData,
                            lineIndex,
                            {
                              ...line,
                              [property]: value,
                              lineTotal,
                            },
                          )
                        );
                      },
                    );
                  }}
              />
            ))}
            <tr>
              <td colSpan={6}>
                <Button
                  onClick={() => addNewDescriptionLine()}
                  style={{ margin: '0.4rem auto' }}
                >
                  Add Description Line
                </Button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={5}>Subtotal</th>
              <th className='currency-right'>
                {
                  invoiceSubtotalAfterEdit === invoiceSubtotal
                    ? toDollarAmount(invoiceSubtotal)
                    : <>
                      <span style={{ color: 'red', fontWeight: 'bold', marginRight: '0.75rem' }}>
                        {toDollarAmount(invoiceSubtotalAfterEdit)}
                      </span>
                      <span style={{ textDecoration: 'line-through' }}>
                        {toDollarAmount(invoiceSubtotal)}
                      </span>
                    </>
                }
              </th>
            </tr>
            {keys(taxData).map((key) => {
              const td = taxData[key];
              return (<tr key={`${td.name}${td.rate}`}>
                <td colSpan={5}>{td.name} ({td.rate}%)</td>
                <td colSpan={1} className='currency-right'>{toDollarAmount(td.amount)}</td>
              </tr>);
            })}
            <tr>
              <th colSpan={5}>Total</th>
              <th colSpan={1} className='currency-right'>{toDollarAmount(invoiceSubtotal + totalTaxAmount)}</th>
            </tr>
            <tr>
              <th colSpan={5}>Paid</th>
              <th colSpan={1} className='currency-right'>{toDollarAmount(paidAmount)}</th>
            </tr>
            <tr>
              <th colSpan={5}>Outstanding</th>
              <th colSpan={1} className='currency-right'>{toDollarAmount(outstandingAmount)}</th>
            </tr>
          </tfoot>
        </Table>
        {hasDirtyLines && <Row className="align-items-end">
          <Col style={{ textAlign: 'right' }}>
            Invoice lines have been edited.
            <Button
              disabled={!(invoiceSubtotalAfterEdit === invoiceSubtotal)}
              style={{ margin: 'auto 0.5rem' }}
              onClick={() => {
                updateInvoiceLines();
              }}
              title={
                !(invoiceSubtotalAfterEdit === invoiceSubtotal)
                  ? 'Please ensure line changes do not cause the subtotal to change.'
                  : ''
              }
            >
              Save
            </Button>
            <Button
              onClick={() => { clearLineChanges(); }}
            >
              Undo
            </Button>
          </Col>
        </Row>}

        {!hasDirtyLines && <Tabs defaultActiveKey="credits" transition={false}>
          <Tab eventKey="credits" title="Credits">
            <Container style={{ padding: '8px' }}>
              <Row>
                <Col>
                  <h5>Available Client Credit:</h5>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  {
                    (maxApplicationAmount > 0 || creditData?.creditNoteSummary.some((s) => s.remainingBalance > 0))
                    && (
                      <Button
                        disabled={creditsLoadedState === 'loading'}
                        onClick={() => setShowApplyCreditModal(true)}
                        style={{ marginBottom: 7 }}
                      >
                          Apply a Credit or Credit Note to this Invoice
                      </Button>
                    )
                  }
                </Col>
              </Row>
              <Table>
                <tbody>
                  <tr>
                    <th>Before Tax:</th>
                    <td style={{ textAlign: 'right' }}>{roundFloatToCents(creditData?.availableSubtotal ?? 0)}</td>
                  </tr>
                  <tr>
                    <th>With Tax:</th>
                    <td style={{ textAlign: 'right' }}>{roundFloatToCents(creditData?.availableTotal ?? 0)}</td>
                  </tr>
                  <tr>
                    <th>Tax Rate: </th>
                    <td style={{ textAlign: 'right' }}>{floatVal(invoice?.taxRate) * 100}%</td>
                  </tr>
                </tbody>
              </Table>
              <Row>
                <Col>
                  <h5>Credit Summary: <Button
                    size="sm"
                    onClick={(e) => toggleShowCreditSummary()}
                  >{showClientCreditSummary ? 'Hide' : 'Show'}</Button></h5>
                </Col>
                {
                  showClientCreditSummary
                  && (
                    <Col style={{ textAlign: 'right' }}>
                      <div>
                        Show Info For:&nbsp;
                        <Form.Check
                          inline
                          label="Client"
                          type="radio"
                          id={`show-all-radio`}
                          checked={!filterCreditSummaryToInvoice}
                          onChange={() => setFilterCreditSummaryToInvoice(false) }
                        />
                        <Form.Check
                          inline
                          label="This Invoice"
                          type="radio"
                          id={`show-invoice-only-radio`}
                          checked={filterCreditSummaryToInvoice}
                          onChange={() => setFilterCreditSummaryToInvoice(true) }
                        />
                      </div>
                      <div>
                        <Form.Check
                          inline
                          label="Awarded"
                          type="checkbox"
                          id={`show-awarded-checkbox`}
                          checked={showAwardedCreditInfo}
                          onChange={(e) => setShowAwardedCreditInfo(e.target.checked) }
                        />
                        <Form.Check
                          inline
                          label="Spent"
                          type="checkbox"
                          id={`show-spent-checkbox`}
                          checked={showSpentCreditInfo}
                          onChange={(e) => setShowSpentCreditInfo(e.target.checked) }
                        />
                      </div>
                    </Col>
                  )
                }
              </Row>
              {
                showClientCreditSummary
                && (
                  <>
                    <TableContainer component={Paper}>
                      <MuiTable>
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell>Identifier</StyledTableCell>
                            <StyledTableCell>Invoice</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell>Subtotal</StyledTableCell>
                            <StyledTableCell>Tax</StyledTableCell>
                            <StyledTableCell>Total</StyledTableCell>
                            <StyledTableCell>Applied as:</StyledTableCell>
                            <StyledTableCell>Credit/Applied Date:</StyledTableCell>
                            <StyledTableCell>Created:</StyledTableCell>
                          </StyledTableRow>
                        </TableHead>

                        <TableBody>
                          {(creditData?.creditSummary ?? [])
                            .filter((cr) => (
                              (!filterCreditSummaryToInvoice || cr.relatedInvoiceId === invoice?.id)
                              && (
                                ((showAwardedCreditInfo && cr.type === 'credit'))
                                || ((showSpentCreditInfo && cr.type === 'spend'))
                              )
                            ))
                            .map((cr) => (
                              <StyledTableRow key={cr.identifier}>
                                <StyledTableCell>{cr.identifier}</StyledTableCell>
                                <StyledTableCell>{cr.relatedInvoiceNumber}</StyledTableCell>
                                <StyledTableCell>{cr.description}</StyledTableCell>
                                <StyledTableCell>
                                  {numberAs(cr.subtotal, cr.type === 'credit' ? 'positive' : 'negative')}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {numberAs(cr.tax, cr.type === 'credit' ? 'positive' : 'negative')}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {numberAs(cr.total, cr.type === 'credit' ? 'positive' : 'negative')}
                                </StyledTableCell>
                                <StyledTableCell>{cr.appliedAs ?? ' - '}</StyledTableCell>
                                <StyledTableCell>{!cr.appliedAs ? cr.creditDate : cr.dateApplied}</StyledTableCell>
                                <StyledTableCell>
                                  {
                                    new Date(cr.createdDate).toLocaleDateString()
                                  }
                                  {' '}
                                  {
                                    new Date(cr.createdDate).toLocaleTimeString()
                                  }
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>

                        <TableFooter>
                          <StyledTableRow>
                            <StyledTableCell>Total</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>{roundFloatToCents(creditData?.availableSubtotal ?? 0)}</StyledTableCell>
                            <StyledTableCell>
                              {
                                roundFloatToCents((creditData?.availableTotal ?? 0)
                                - (creditData?.availableSubtotal ?? 0))
                              }
                            </StyledTableCell>
                            <StyledTableCell>{roundFloatToCents(creditData?.availableTotal ?? 0)}</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                          </StyledTableRow>
                        </TableFooter>
                      </MuiTable>
                    </TableContainer>
                  </>
                )
              }
              <hr />
              <br />

              <h5>Credit Note Summary: <Button
                size="sm"
                onClick={(e) => toggleShowCreditNoteSummary()}
              >{showClientCreditNoteSummary ? 'Hide' : 'Show'}</Button>
              </h5>

              {
                showClientCreditNoteSummary
                && (
                  <Col style={{ textAlign: 'right', marginBottom: '5px' }}>
                    <div>
                      Show Info For:&nbsp;
                      <Form.Check
                        inline
                        label="Client"
                        type="radio"
                        id={`show-all-radio`}
                        checked={!filterCreditNoteSummaryToInvoice}
                        onChange={() => setFilterCreditNoteSummaryToInvoice(false) }
                      />
                      <Form.Check
                        inline
                        label="This Invoice"
                        type="radio"
                        id={`show-invoice-only-radio`}
                        checked={filterCreditNoteSummaryToInvoice}
                        onChange={() => setFilterCreditNoteSummaryToInvoice(true) }
                      />
                    </div>
                  </Col>
                )
              }

              {
                showClientCreditNoteSummary
                  && (
                    ((creditData?.creditNoteSummary ?? []).length > 0)
                      ? (
                        <>
                          <Typography
                            variant='h5'
                            mt={2}
                            mb={1}
                            textAlign='center'
                          >
                            Existing Client Credit Notes
                          </Typography>
                          <TableContainer component={Paper} sx={{ mb: 3 }}>
                            <MuiTable>
                              <TableHead>
                                <StyledTableRow dark={true}>
                                  <StyledTableCell dark={true}>Credit Note #</StyledTableCell>
                                  <StyledTableCell dark={true}>Note Date</StyledTableCell>
                                  <StyledTableCell dark={true}>Remaining Balance</StyledTableCell>
                                </StyledTableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  creditData?.creditNoteSummary
                                    .filter(
                                      (summary) => (
                                        summary.remainingBalance !== 0
                                        && (
                                          filterCreditNoteSummaryToInvoice
                                            ? summary.payments.some((p) => p.invoiceId === invoice?.id)
                                            : true
                                        )
                                      ),
                                    )
                                    .slice()
                                    .sort((a, b) => a.remainingBalance > b.remainingBalance ? -1 : 1)
                                    .map((cn) => (
                                      <StyledTableRow key={cn.id} dark={true}>
                                        <StyledTableCell dark={true}>{cn.identifier}</StyledTableCell>
                                        <StyledTableCell dark={true}>
                                          {new Date(Date.parse(cn.creditNoteDate ?? '')).toLocaleDateString()}
                                        </StyledTableCell>
                                        <StyledTableCell dark={true}>
                                          {toDollarAmount(cn.remainingBalance)}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))
                                }
                              </TableBody>
                            </MuiTable>
                          </TableContainer>

                          <Typography
                            variant='h5'
                            mt={6}
                            mb={1}
                            textAlign='center'
                          >
                            Credit Notes Usage
                          </Typography>
                          <TableContainer component={Paper}>
                            <MuiTable>
                              <TableHead>
                                <StyledTableRow>
                                  <StyledTableCell>Credit Note #</StyledTableCell>
                                  <StyledTableCell>Note Date</StyledTableCell>
                                  <StyledTableCell>Subtotal</StyledTableCell>
                                  <StyledTableCell>Tax</StyledTableCell>
                                  <StyledTableCell>Total</StyledTableCell>
                                  <StyledTableCell>Applied Amount</StyledTableCell>
                                  {
                                    filterCreditNoteSummaryToInvoice
                                      ? <></>
                                      : <StyledTableCell>Invoice</StyledTableCell>
                                  }
                                  <StyledTableCell>Paid Date</StyledTableCell>
                                </StyledTableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  creditData?.creditNoteSummary
                                    .reduce<{ payment: IPayment, summary: ICreditNoteSummaryItem }[]>(
                                    (acc, summary) => [
                                      ...acc,
                                      ...(
                                        ((summary.payments) ?? [])
                                          .filter(
                                            (p) => (
                                              p.hydraState === EHydraPaymentState.active
                                              && (
                                                filterCreditNoteSummaryToInvoice
                                                  ? p.invoiceId === invoice?.id
                                                  : true
                                              )
                                            ),
                                          )
                                          .map((p) => ({
                                            payment: p,
                                            summary,
                                          }))
                                      ),
                                    ],
                                    [],
                                  )
                                    .slice()
                                    .sort(
                                      (a, b) => (
                                        (filterCreditNoteSummaryToInvoice
                                          ? (a.payment.created < b.payment.created)
                                          : (
                                            (
                                              a.payment.invoice?.invoiceNumber ?? '')
                                              < (b.payment.invoice?.invoiceNumber ?? ''
                                              )
                                              || (
                                                (
                                                  (a.payment.invoice?.invoiceNumber ?? '')
                                                  === (b.payment.invoice?.invoiceNumber ?? '')
                                                )
                                                && a.payment.created < b.payment.created
                                              )
                                          )
                                        ) ? -1 : 1
                                      ),
                                    )
                                    .map(({ payment, summary }) => (
                                      <StyledTableRow key={payment.id}>
                                        <StyledTableCell>CN # {floatVal(summary.identifier)}</StyledTableCell>
                                        <StyledTableCell>
                                          {
                                            summary.creditNoteDate
                                              ? new Date(Date.parse(summary.creditNoteDate)).toLocaleDateString()
                                              : ''
                                          }
                                        </StyledTableCell>
                                        <StyledTableCell>{roundFloatToCents(summary.subtotal)}</StyledTableCell>
                                        <StyledTableCell>{roundFloatToCents(summary.tax)}</StyledTableCell>
                                        <StyledTableCell>{roundFloatToCents(summary.total)}</StyledTableCell>
                                        <StyledTableCell>
                                          {roundFloatToCents(floatVal(payment.amount))}
                                        </StyledTableCell>
                                        {
                                          filterCreditNoteSummaryToInvoice
                                            ? <></>
                                            : (
                                              <StyledTableCell>
                                                {
                                                  payment.invoice?.invoiceNumber
                                                  ?? (payment.invoice ? `ID: ${payment.invoice.id}` : '')
                                                }
                                              </StyledTableCell>
                                            )
                                        }
                                        <StyledTableCell>
                                          {getPartsFromIsoString(payment.datePaid, 'date')}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))
                                }
                              </TableBody>
                            </MuiTable>
                          </TableContainer>
                        </>
                      )
                      : (
                        <Typography
                          variant='h6'
                          textAlign='center'
                          mt={4}
                        >
                          No credit notes exist for client.
                        </Typography>
                      )
                  )
              }
            </Container>
          </Tab>
          <Tab eventKey="sendHistory" title="Send History">
            <Table>
              <thead>
                <tr>
                  <th>Sender</th>
                  <th>Recipient</th>
                  <th>Sent</th>
                </tr>
              </thead>
              <tbody>
                {sendHistories?.map((sendHistory) => (
                  <tr key={sendHistory.id}>
                    <td>{`${sendHistory.createdByUserName} (ID: ${sendHistory.createdUserId})`}</td>
                    <td>{`${sendHistory.name} <${sendHistory.email}>`}</td>
                    <td>
                      {new Date(
                        `${sendHistory.sentTime ?? ''}`,
                      ).toLocaleDateString()}
                      {` `}
                      {new Date(
                        `${sendHistory.sentTime ?? ''}`,
                      ).toLocaleTimeString()}
                    </td>
                  </tr>
                ))}
              </tbody>

            </Table>
          </Tab>
          <Tab eventKey="paymentHistory" title="Payment History">
          </Tab>
        </Tabs>}
      </Container>
    </>
  );
};
